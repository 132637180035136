// import i18n from '../../lib/localization'
import { salextApi } from '../../api'
import { setUser } from '../actions/setting'

const middleware = (store) => (next) => async (action) => {
  if (action.type === 'setting/loadUser') {
    const result = await salextApi.get('/users/self')
    if (result.status === 200) {
      store.dispatch(setUser(result.data))
    }
    // Payload can be modified before passing to the reducer
    // action.payload.user = result.data;
  }

  if (action.type === 'setting/setUser') {
  }

  if (action.type === 'setting/setLocale') {
    i18n.locale = action.payload?.locale
  }

  next(action)
}

export default middleware

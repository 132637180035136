export const enableProtectedSocket = () => ({
  type: `socket/enableProtectedSocket`,
  payload: {}
})

export const disableProtectedSocket = () => ({
  type: 'socket/disableProtectedSocket',
  payload: {}
})

export const setSocketConnectionStatus = (isConnected) => ({
  type: 'socket/setSocketConnectionStatus',
  payload: { isConnected }
})

export const sendData = (type, payload) => ({
  type: 'socket/sendData',
  payload: { type, payload }
})

import { setRefreshToken, setAccessToken } from '../actions/auth'
import { initialEventListener } from '../actions/serverEvents'

/* eslint-disable no-unused-vars */
const middleware = (store) => (next) => async (action) => {
  if (action.type === 'auth/loadAuthTokens') {
    const authenticationData = JSON.parse(localStorage.getItem('authenticationData'))
    if (authenticationData !== null) {
      if (authenticationData.refreshToken !== undefined && authenticationData.refreshToken === null) {
        localStorage.removeItem('authenticationData')
        window.location.href = '/login'
        return
      }
      if (window.location.href.startsWith(`${window.location.origin}/login`)) window.location.href = '/home'
      if (window.location.href.startsWith(`${window.location.origin}/not-authorized`)) window.location.href = '/home'
      if (window.location.href.startsWith(`${window.location.origin}/suspended`)) window.location.href = '/home'
      store.dispatch(setRefreshToken(authenticationData.refreshToken))
      store.dispatch(setAccessToken(authenticationData.accessToken))
      store.dispatch(initialEventListener(authenticationData.accessToken))
    } else {
      if (!window.location.href.startsWith(`${window.location.origin}/authenticate`))
        if (window.location.href !== `${window.location.origin}/login`) window.location.href = '/login'
    }
  }

  if (action.type === 'auth/setRefreshToken') {
    if (action.payload?.accessToken !== null) {
      const auth = store.getState().auth
      if (auth.refreshToken !== action.payload?.refreshToken) {
        localStorage.setItem(
          'authenticationData',
          JSON.stringify({
            ...auth,
            refreshToken: action.payload?.refreshToken
          })
        )
      }
    }
  }

  if (action.type === 'auth/resetRefreshToken') {
    localStorage.removeItem('authenticationData')
    // TODO: Implement logout logic with Auth0 here.
  }

  if (action.type === 'auth/setAccessToken') {
    if (action.payload?.accessToken !== null) {
      const auth = store.getState().auth
      if (auth.accessToken !== action.payload?.accessToken) {
        localStorage.setItem(
          'authenticationData',
          JSON.stringify({
            ...auth,
            accessToken: action.payload?.accessToken
          })
        )
      }
    }
  }

  if (action.type === 'auth/resetAccessToken') {
    localStorage.removeItem('authenticationData')
    // // TODO: Implement logout logic with Auth0 here.
  }

  next(action)
}
/* eslint-enable no-unused-vars */

export default middleware

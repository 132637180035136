import { SocketClient } from '../../lib/SocketClient'

export class ProtectedSocketClient extends SocketClient {
  constructor(uri, callback = null, authOptions = null, eventCallbacks = null) {
    super(uri, callback, authOptions, eventCallbacks)
  }

  sendMessage(userId, message) {
    this.emit('sendMessage', { to: userId, message })
  }
}

import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'uiStates',
  initialState: {
    premadePageTab: '1',
    taskDetailSidebarParameters: null,
    checklistCreationSidebarParameters: null,
    taskAssigneeSelectorSidebarParameters: null,
    delegateSidebarParameters: null,
    cloneSidebarParameters: null,
    shareSidebarParameters: null
  },
  reducers: {
    setPremadePageTab: (state, action) => {
      state.premadePageTab = action.payload?.tabIndex
    },
    setTaskDetailSidebarParameters: (state, action) => {
      state.taskDetailSidebarParameters = action.payload.data
    },
    setChecklistCreationSidebarParameters: (state, action) => {
      state.checklistCreationSidebarParameters = action.payload.data
    },
    setDelegateSidebarParameters: (state, action) => {
      state.delegateSidebarParameters = action.payload.data
    },
    setCloneSidebarParameters: (state, action) => {
      state.cloneSidebarParameters = action.payload.data
    },
    setShareSidebarParameters: (state, action) => {
      state.shareSidebarParameters = action.payload.data
    }
  }
})

export default slice.reducer

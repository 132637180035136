/* eslint-disable no-unused-vars */
import { constants } from '../../constants'
import { setEventListenerStatus } from '../actions/serverEvents'
// import {
//   initialTodoData,
//   refreshAvailableData,
//   refreshCurrentSelections,
//   refreshDelegatesData,
//   refreshPremadesData
// } from '../actions/todo'

// let eventSource = null

const middleware = (store) => (next) => async (action) => {
  const dispatchProxy = (action) => {
    store.dispatch(action)
  }

  // if (action.type === 'serverEvents/initialEventListener') {
  //   if (!action.payload?.accessToken) return

  //   eventSource = new EventSource(`${constants.serviceBaseUrl}/sse?accessToken=${action.payload?.accessToken}`)
  //   eventSource.addEventListener('message', (event) => {
  //     try {
  //       /*
  //         the logic below is obsolete and will use socket/protected/handler instead
  //       */
  //       // const data = JSON.parse(event.data)
  //       // const user = store.getState().setting.user
  //       // console.log(user.name, '->', data.type)
  //       // if (data.type === 'reloadData') {
  //       //   store.dispatch(initialTodoData())
  //       // }
  //       // if (data.type === 'refreshMyData') {
  //       //   store.dispatch(initialTodoData())
  //       // }
  //       // if (data.type === 'refreshPremadesData') {
  //       //   store.dispatch(refreshPremadesData())
  //       // }
  //       // if (data.type === 'refreshDelegatesData') {
  //       //   store.dispatch(refreshDelegatesData())
  //       // }
  //       // if (data.type === 'refreshSharesData') {
  //       //   store.dispatch(initialTodoData())
  //       // }
  //       // if (data.type === 'refreshAvailableData') {
  //       //   store.dispatch(refreshAvailableData())
  //       // }
  //       // if (data.type === 'refreshCurrentSelections') {
  //       //   store.dispatch(refreshCurrentSelections())
  //       // }
  //     } catch (err) {}
  //   })

  //   setInterval(() => {
  //     dispatchProxy(setEventListenerStatus(eventSource.readyState))
  //   }, 3000)
  // }

  next(action)
}

export default middleware

import io from 'socket.io-client'

export class SocketClient {
  constructor(uri, callback = null, authOptions = null, eventCallbacks = null) {
    if (authOptions) {
      if (authOptions.accessTokenGetter) this.accessTokenGetter = authOptions.accessTokenGetter
      else throw new Error('authOptions must contain accessTokenGetter')

      if (!(authOptions.refreshTokenGetter && authOptions.accessTokenSetter))
        throw new Error('refreshTokenGetter and accessTokenSetter are dependent, one of function is missing')
      if (authOptions.refreshTokenGetter) this.refreshTokenGetter = authOptions.refreshTokenGetter
      if (authOptions.accessTokenSetter) this.accessTokenSetter = authOptions.accessTokenSetter

      if (authOptions.accessTokenEnsurer) {
        if (!authOptions.refreshTokenGetter || !authOptions.accessTokenSetter)
          throw new Error(
            'accessTokenEnsurer required refreshTokenGetter and accessTokenSetter options to be available'
          )
        this.accessTokenEnsurer = authOptions.accessTokenEnsurer
      }
    }

    if (eventCallbacks) {
      if (eventCallbacks.connectCallback) this.connectCallback = eventCallbacks.connectCallback
      if (eventCallbacks.disconnectCallback) this.disconnectCallback = eventCallbacks.disconnectCallback
      if (eventCallbacks.unauthorizeCallback) this.unauthorizeCallback = eventCallbacks.unauthorizeCallback
      if (eventCallbacks.accessTokenExpiredCallback)
        this.accessTokenExpiredCallback = eventCallbacks.accessTokenExpiredCallback
      if (eventCallbacks.newAccessTokenIssuedCallback)
        this.newAccessTokenIssuedCallback = eventCallbacks.newAccessTokenIssuedCallback
    }

    this.socket = io(uri, { autoConnect: false })
    this.callback = callback

    this.socket.on('connect', () => {
      if (this.connectCallback)
        setTimeout(() => {
          this.connectCallback()
        }, 0)
    })

    this.socket.on('disconnect', (data) => {
      if (this.disconnectCallback)
        setTimeout(() => {
          this.disconnectCallback(data)
        }, 0)
    })

    this.socket.on('unauthorize', (data) => {
      if (this.unauthorizeCallback)
        setTimeout(() => {
          this.unauthorizeCallback(data)
        }, 0)
    })

    this.socket.on('accessTokenExpired', async (data) => {
      if (this.accessTokenExpiredCallback)
        setTimeout(() => {
          this.accessTokenExpiredCallback(data)
        }, 0)

      if (this.refreshTokenGetter)
        this.socket.emit('renewAccessToken', { refreshToken: await this.refreshTokenGetter() })
      else this.socket.disconnect()
    })

    this.socket.on('newAccessTokenIssued', async (data) => {
      if (this.newAccessTokenIssuedCallback)
        setTimeout(() => {
          this.newAccessTokenIssuedCallback(data)
        }, 0)

      if (this.accessTokenSetter) await this.accessTokenSetter(data.accessToken)
    })

    this.socket.on('data', (data) => {
      const type = data.type
      const payload = data.payload ? data.payload : null
      if (this.callback) this.callback(type, payload)
    })
  }

  async connect() {
    if (!this.socket.connected) {
      if (this.accessTokenEnsurer) await this.accessTokenEnsurer(await this.refreshTokenGetter())
      if (this.accessTokenGetter)
        this.socket.io.opts = { ...this.socket.io.opts, query: `accessToken=${await this.accessTokenGetter()}` }

      this.socket.open()
    }
  }

  disconnect() {
    if (this.socket.connected) this.socket.disconnect()
  }

  async emit(type, payload) {
    if (this.socket.connected) this.socket.emit('data', { type, payload })
  }
}

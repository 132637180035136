import { createSlice } from '@reduxjs/toolkit'

const conditionalFilter = (aggregatedWorkItems) => {
  return aggregatedWorkItems
    .filter((item) => {
      if (
        item.source === 'delegatedTasksToMe' &&
        item.data.state &&
        (item.data.state.value === 'declined' || item.data.state.value === 'overdue')
      )
        return false
      else return true
    })
    .map((elm) => {
      if (elm.source === 'delegatedChecklistsToMe') {
        return {
          ...elm,
          data: {
            ...elm.data,
            tasks: [
              ...elm.data.tasks.filter((elm2) => {
                if (elm2.state && (elm2.state.value === 'declined' || elm2.state.value === 'overdue')) return false
                else return true
              })
            ]
          }
        }
      } else return elm
    })
    .filter((elm) => {
      if (elm.source === 'delegatedChecklistsToMe' && elm.data.tasks.length === 0) return false
      else return true
    })
}

export const slice = createSlice({
  name: 'todo',
  initialState: {
    users: [],
    groups: [],
    entities: [],
    categories: [],
    importances: [],
    repetitions: [],
    aggregatedWorkItems: [],
    currentTask: null,
    currentChecklist: null,
    subtasksDraft: {}
  },
  reducers: {
    setUsers: (state, action) => {
      state.users = [...action.payload.users]
    },
    setGroups: (state, action) => {
      state.groups = [...action.payload.groups]
    },
    setEntities: (state, action) => {
      state.entities = [...action.payload.entities]
    },
    setCategories: (state, action) => {
      state.categories = [...action.payload.categories]
    },
    setImportances: (state, action) => {
      state.importances = [...action.payload.importances]
    },
    setRepetitions: (state, action) => {
      state.repetitions = [...action.payload.repetitions]
    },
    setAggregatedWorkItems: (state, action) => {
      state.aggregatedWorkItems = [...conditionalFilter(action.payload.aggregatedWorkItems)]
    },
    replacePremadesData: (state, action) => {
      state.aggregatedWorkItems = [
        ...state.aggregatedWorkItems.filter(
          (elm) => elm.source !== 'premadeTasks' && elm.source !== 'premadeChecklists'
        ),
        ...action.payload.aggregatedWorkItems
      ]
    },
    replaceDelegatesData: (state, action) => {
      state.aggregatedWorkItems = [
        ...state.aggregatedWorkItems.filter(
          (elm) =>
            elm.source !== 'delegatedTasksToOthers' &&
            elm.source !== 'delegatedTasksToMe' &&
            elm.source !== 'delegatedChecklistsToOthers' &&
            elm.source !== 'delegatedChecklistsToMe'
        ),
        ...conditionalFilter(action.payload.aggregatedWorkItems)
      ]
    },
    replaceSharesData: (state, action) => {
      state.aggregatedWorkItems = [
        ...state.aggregatedWorkItems.filter(
          (elm) =>
            elm.source !== 'tasks' &&
            elm.source !== 'checklists' &&
            elm.source !== 'sharedTasksToOthers' &&
            elm.source !== 'sharedTasksToMe' &&
            elm.source !== 'sharedChecklistsToOthers' &&
            elm.source !== 'sharedChecklistsToMe'
        ),
        ...action.payload.aggregatedWorkItems
      ]
    },
    replaceAvailableData: (state, action) => {
      state.aggregatedWorkItems = [
        ...state.aggregatedWorkItems.filter(
          (elm) => elm.source !== 'availableTasks' && elm.source !== 'availableChecklists'
        ),
        ...action.payload.aggregatedWorkItems
      ]
    },
    setCurrentTask: (state, action) => {
      // This code block allow to set current viewing task without validate with store, strictly use with report feature
      // where the data is loaded directly from the endpoint for viewing purpose.
      if (action.payload.item.bypassValidation) {
        state.currentTask = action.payload.item
        return
      }

      if (action.payload.item === null) {
        state.currentTask = null
      } else {
        const id = action.payload.item.data.id
        const source = action.payload.item.source
        const checklistId = action.payload.item.data.checklist ? action.payload.item.data.checklist.id : null
        if (
          source === 'tasks' ||
          source === 'sharedTasksToOthers' ||
          source === 'delegatedTasksToOthers' ||
          source === 'sharedTasksToMe' ||
          source === 'delegatedTasksToMe' ||
          checklistId === null
        ) {
          const item = state.aggregatedWorkItems.find((elm) => elm.source === source && elm.data.id === id)
          if (item) {
            state.currentTask = item
          } else {
            state.currentTask = null
          }
        } else if (checklistId !== null) {
          const checklist = (() => {
            const _checklists = state.aggregatedWorkItems.filter((elm) => elm.data.id === checklistId)

            if (_checklists.length === 1) return _checklists[0]
            else if (_checklists.length > 1) return _checklists.find((elm) => elm.source === source)
            else return undefined
          })()

          let task = null
          if (checklist !== undefined) {
            task = checklist.data.tasks.find((elm) => elm.id === id)
          }

          if (checklist && task) {
            state.currentTask = {
              source,
              checklistId,
              data: {
                ...task,
                checklist: {
                  ...task.checklist,
                  id: checklist.data.id,
                  delegates: checklist.data.delegates,
                  shares: checklist.data.shares
                }
              }
            }
          } else {
            state.currentTask = null
          }
        }
      }
    },
    setCurrentChecklist: (state, action) => {
      if (action.payload.item === null) {
        state.currentChecklist = null
      } else {
        const id = action.payload.item.data.id
        const source = action.payload.item.source

        const checklist = state.aggregatedWorkItems.find((elm) => elm.source === source && elm.data.id === id)

        if (checklist) state.currentChecklist = checklist
        else state.currentChecklist = null
      }
    },
    setSubtasksDraft: (state, action) => {
      state.subtasksDraft = action.payload.subtasksDraft
    }
  }
})

export default slice.reducer

import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'auth',
  initialState: {
    refreshToken: null,
    accessToken: null
  },
  reducers: {
    loadAuthTokens: () => {},
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload['refreshToken']
    },
    resetRefreshToken: (state) => {
      state.refreshToken = null
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload['accessToken']
    },
    resetAccessToken: (state) => {
      state.accessToken = null
    }
  }
})

export default slice.reducer

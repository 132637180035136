export const initialTodoData = () => ({
  type: `todo/initialTodoData`,
  payload: {},
  meta: {
    debounce: {
      time: 200,
      type: 'initialTodoData',
      leading: true,
      trailing: false
    }
  }
})

export const setAggregatedWorkItems = (aggregatedWorkItems) => ({
  type: `todo/setAggregatedWorkItems`,
  payload: { aggregatedWorkItems }
})

export const refreshPremadesData = () => ({
  type: `todo/refreshPremadesData`,
  payload: {}
})

export const replacePremadesData = (aggregatedWorkItems) => ({
  type: `todo/replacePremadesData`,
  payload: { aggregatedWorkItems }
})

export const refreshDelegatesData = () => ({
  type: `todo/refreshDelegatesData`,
  payload: {}
})

export const replaceDelegatesData = (aggregatedWorkItems) => ({
  type: `todo/replaceDelegatesData`,
  payload: { aggregatedWorkItems }
})

export const refreshSharesData = () => ({
  type: `todo/refreshSharesData`,
  payload: {}
})

export const replaceSharesData = (aggregatedWorkItems) => ({
  type: `todo/replaceSharesData`,
  payload: { aggregatedWorkItems }
})

export const refreshAvailableData = () => ({
  type: `todo/refreshAvailableData`,
  payload: {}
})

export const replaceAvailableData = (aggregatedWorkItems) => ({
  type: `todo/replaceAvailableData`,
  payload: { aggregatedWorkItems }
})

export const loadVariables = () => ({
  type: `todo/loadVariables`,
  payload: {}
})

export const loadUsers = () => ({
  type: `todo/loadUsers`,
  payload: {}
})

export const loadGroups = () => ({
  type: `todo/loadGroups`,
  payload: {}
})

export const loadEntities = () => ({
  type: `todo/loadEntities`,
  payload: {}
})

export const setUsers = (users) => ({
  type: `todo/setUsers`,
  payload: { users }
})

export const setGroups = (groups) => ({
  type: `todo/setGroups`,
  payload: { groups }
})

export const setEntities = (entities) => ({
  type: `todo/setEntities`,
  payload: { entities }
})

export const setCategories = (categories) => ({
  type: `todo/setCategories`,
  payload: { categories }
})

export const setImportances = (importances) => ({
  type: `todo/setImportances`,
  payload: { importances }
})

export const setRepetitions = (repetitions) => ({
  type: `todo/setRepetitions`,
  payload: { repetitions }
})

export const completeTask = (id) => ({
  type: `todo/completeTask`,
  payload: { id }
})

export const uncompleteTask = (id) => ({
  type: `todo/uncompleteTask`,
  payload: { id }
})

export const declineTask = (id, reason) => ({
  type: `todo/declineTask`,
  payload: { id, reason }
})

export const approveTask = (id, feedbackComment) => ({
  type: `todo/approveTask`,
  payload: { id, feedbackComment }
})

export const rejectTask = (id, feedbackComment) => ({
  type: `todo/rejectTask`,
  payload: { id, feedbackComment }
})

export const suspendTask = (id) => ({
  type: `todo/suspendTask`,
  payload: { id }
})

export const unsuspendTask = (id) => ({
  type: `todo/unsuspendTask`,
  payload: { id }
})

export const setCurrentTask = (item) => ({
  type: `todo/setCurrentTask`,
  payload: { item }
})

export const setCurrentChecklist = (item) => ({
  type: `todo/setCurrentChecklist`,
  payload: { item }
})

export const refreshCurrentSelections = () => ({
  type: `todo/refreshCurrentSelections`,
  payload: {}
})

export const setSubtasksDraft = (subtasksDraft) => ({
  type: `todo/setSubtasksDraft`,
  payload: { subtasksDraft }
})

export const addTask = (task, source) => ({
  type: `todo/addTask`,
  payload: { data: task, source }
})

export const updateTask = (task) => ({
  type: `todo/updateTask`,
  payload: { data: task }
})

export const addChecklist = (checklist, source) => ({
  type: `todo/addChecklist`,
  payload: { data: checklist, source }
})

export const updateChecklist = (checklist) => ({
  type: `todo/updateChecklist`,
  payload: { data: checklist }
})

export const removeTask = (data) => ({
  type: `todo/removeTask`,
  payload: { data }
})

export const removeChecklist = (data) => ({
  type: `todo/removeChecklist`,
  payload: { data }
})

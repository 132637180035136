// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './auth'
import serverEvents from './serverEvents'
import setting from './setting'
import socket from './socket'
import todo from './todo'
import uiStates from './uiStates'

const rootReducer = {
  navbar,
  layout,
  auth,
  serverEvents,
  setting,
  socket,
  todo,
  uiStates
}

export default rootReducer

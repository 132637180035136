import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'setting',
  initialState: {
    user: null,
    locale: 'en'
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload?.user
    },
    setLocale: (state, action) => {
      state.locale = action.payload?.locale
    }
  }
})

export default slice.reducer

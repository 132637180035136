export const loadUser = () => ({
  type: `setting/loadUser`,
  payload: {}
})

export const setUser = (user) => ({
  type: `setting/setUser`,
  payload: { user }
})

export const setLocale = (locale) => ({
  type: `setting/setLocale`,
  payload: { locale }
})

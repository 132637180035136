export const loadAuthTokens = () => ({
  type: `auth/loadAuthTokens`,
  payload: {}
})

export const setRefreshToken = (refreshToken) => ({
  type: `auth/setRefreshToken`,
  payload: { refreshToken }
})

export const resetRefreshToken = () => ({
  type: `auth/resetRefreshToken`,
  payload: {}
})

export const setAccessToken = (accessToken) => ({
  type: `auth/setAccessToken`,
  payload: { accessToken }
})

export const resetAccessToken = () => ({
  type: `auth/resetAccessToken`,
  payload: {}
})

import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'serverEvents',
  initialState: { eventListenerStatus: 'notStarted' },
  reducers: {
    initialEventListener: () => {},
    setEventListenerStatus: (state, action) => {
      if (action.payload?.status === 0) state.eventListenerStatus = 'connecting'
      else if (action.payload?.status === 1) state.eventListenerStatus = 'open'
      else if (action.payload?.status === 2) state.eventListenerStatus = 'closed'
      else state.eventListenerStatus = 'unknown'
    }
  }
})

export default slice.reducer

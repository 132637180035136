// ** Redux Imports
import rootReducer from './reducers/rootReducer'
import { configureStore } from '@reduxjs/toolkit'
import authMiddleware from './middleware/auth'
import serverEventsMiddleware from './middleware/serverEvents'
import settingMiddleware from './middleware/setting'
import socketMiddleware from './middleware/socket'
import todoMiddleware from './middleware/todo'
import createDebounce from 'redux-debounced'

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    })
      .concat(createDebounce())
      .concat(authMiddleware)
      .concat(serverEventsMiddleware)
      .concat(settingMiddleware)
      .concat(socketMiddleware)
      .concat(todoMiddleware)
  }
})

export { store }

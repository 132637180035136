export const setPremadePageTab = (tabIndex) => ({
  type: `uiStates/setPremadePageTab`,
  payload: { tabIndex }
})

export const setTaskDetailSidebarParameters = (data) => ({
  type: `uiStates/setTaskDetailSidebarParameters`,
  payload: { data }
})

export const setChecklistCreationSidebarParameters = (data) => ({
  type: `uiStates/setChecklistCreationSidebarParameters`,
  payload: { data }
})

export const setDelegateSidebarParameters = (data) => ({
  type: `uiStates/setDelegateSidebarParameters`,
  payload: { data }
})

export const setCloneSidebarParameters = (data) => ({
  type: `uiStates/setCloneSidebarParameters`,
  payload: { data }
})

export const setShareSidebarParameters = (data) => ({
  type: `uiStates/setShareSidebarParameters`,
  payload: { data }
})

import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'socket',
  initialState: {
    isConnected: false,
    connectedUsers: []
  },
  reducers: {
    enableProtectedSocket: () => {},
    disableProtectedSocket: () => {},
    setSocketConnectionStatus: (state, action) => {
      state.isConnected = action.payload?.isConnected
    }
  }
})

export default slice.reducer

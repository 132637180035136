import { socket } from '../../socket/protected'

const middleware = () => (next) => async (action) => {
  if (action.type === 'socket/enableProtectedSocket') {
    socket.connect()
  }

  if (action.type === 'socket/disableProtectedSocket') {
    socket.disconnect()
  }

  if (action.type === 'socket/sendData') {
    socket.emit(action.payload)
  }

  next(action)
}

export default middleware

// import moment from 'moment'
import moment from 'moment'
import { salextApi } from '../../api'
import {
  // initialTodoData,
  setCategories,
  setImportances,
  setRepetitions,
  setUsers,
  setGroups,
  setCurrentTask,
  setCurrentChecklist,
  refreshCurrentSelections,
  setAggregatedWorkItems,
  replaceDelegatesData,
  replaceSharesData,
  replacePremadesData,
  setEntities,
  replaceAvailableData
} from '../actions/todo'
// import { setTaskDetailSidebarParameters } from '../../redux/actions/uiStates'
import // setDelegateSidebarParameters,
// setShareSidebarParameters,
// setTaskDetailSidebarParameters
'../../redux/actions/uiStates'
import { PostType } from '../../lib/utility'

const getMinDateFromChecklistWorkItem = (workItem) => {
  if (!workItem.tasks) return null

  if (workItem.tasks.length === 0) return null
  else if (workItem.tasks.length > 0) {
    if (workItem.tasks.filter((elm2) => elm2.dueDate !== null).length === 0) return null

    let minDate = moment().add(100, 'years')
    workItem.tasks.map((elm2) => {
      if (elm2.dueDate && moment(elm2.dueDate) < minDate) minDate = moment(elm2.dueDate)
    })
    return minDate.toISOString()
  }
}

const handleRedelegateItems = (aggregatedData) => {
  // Check delegatedToMe items whether they are also existing in delegatedToOthers, if so meaning
  // those items are redelegated and need to be removed (for type delegatedToMe), only delegatedToOthers
  // will be retained

  // Case 1: When single tasks or the entire list get redelegated.
  const removeItemIds = []
  aggregatedData
    .filter(
      (elm) =>
        elm.source === 'delegatedTasksToMe' ||
        elm.source === 'delegatedChecklistsToMe' ||
        elm.source === 'publishedChecklists'
    )
    .map((elm) => {
      if (
        aggregatedData.filter(
          (elm2) =>
            (elm2.source === 'delegatedTasksToOthers' || elm2.source === 'delegatedChecklistsToOthers') &&
            elm2.data.id === elm.data.id
        ).length > 0
      )
        removeItemIds.push(elm.data.id)
    })

  if (removeItemIds.length > 0)
    aggregatedData = aggregatedData.filter(
      (elm) =>
        !(
          removeItemIds.includes(elm.data.id) &&
          (elm.source === 'delegatedTasksToMe' ||
            elm.source === 'delegatedChecklistsToMe' ||
            elm.source === 'publishedChecklists')
        )
    )

  // Case 2: When tasks under the list get redelegated.
  // Looking for delegatedTasksToOthers, see if it exists in any delegatedChecklistsToMe or publishedChecklists
  // If so, then you need to remove it from the list.
  const removeChecklistItemIds = []
  aggregatedData
    .filter((elm) => elm.source === 'delegatedTasksToOthers')
    .map((elm) => {
      aggregatedData
        .filter(
          (elm2) =>
            (elm2.source === 'delegatedChecklistsToMe' || elm2.source === 'publishedChecklists') &&
            elm2.data.tasks.filter((elm3) => elm3.id === elm.data.id).length > 0
        )
        .map((elm2) => {
          removeChecklistItemIds.push({
            checklistId: elm2.data.id,
            taskId: elm.data.id
          })
        })
    })
  if (removeChecklistItemIds.length > 0)
    removeChecklistItemIds.map((elm) => {
      aggregatedData
        .filter((elm2) => elm2.data.id === elm.checklistId)
        .map((elm2) => {
          elm2.data.tasks = elm2.data.tasks.filter((elm3) => elm3.id !== elm.taskId)
        })
    })

  return aggregatedData
}

const handleMultipleAssignments = (aggregatedData) => {
  // It is possible that the user received multiple assignment on the same task. e.g. the user has list delegated
  // to him then afterward the store manager also delegated one of the task under that list to him again.
  // In this case, only the item on the list should be retained on the user UI. This also applied to task/list sharing.
  const removeItemIds = []
  aggregatedData
    .filter(
      (elm) =>
        elm.source === 'delegatedTasksToMe' ||
        elm.source === 'sharedTasksToMe' ||
        elm.source === 'delegatedTasksToOthers' ||
        elm.source === 'sharedTasksToOthers'
    )
    .map((elm) => {
      // if (elm.data.name === "Financial Planning and Analysis") {
      //   console.log("founded", elm.source, elm.data.name);
      //   console.log(
      //     "nnn",
      //     aggregatedData.filter(
      //       (elm2: any) =>
      //         (elm2.source === "delegatedChecklistsToMe" ||
      //           elm2.source === "sharedChecklistsToMe" ||
      //           elm2.source === "delegatedChecklistsToOthers" ||
      //           elm2.source === "sharedChecklistsToOthers") &&
      //         elm2.data.tasks.filter((elm3: any) => elm3.id === elm.data.id)
      //           .length > 0
      //     ).length
      //   );
      // }

      if (
        aggregatedData.filter(
          (elm2) =>
            (elm2.source === 'delegatedChecklistsToMe' ||
              elm2.source === 'sharedChecklistsToMe' ||
              elm2.source === 'delegatedChecklistsToOthers' ||
              elm2.source === 'sharedChecklistsToOthers') &&
            elm2.data.tasks.filter((elm3) => elm3.id === elm.data.id).length > 0
        ).length > 0
      ) {
        removeItemIds.push(elm.data.id)
      }
    })

  if (removeItemIds.length > 0) {
    // Remove
    aggregatedData = aggregatedData.filter(
      (elm) =>
        !(
          removeItemIds.includes(elm.data.id) &&
          (elm.source === 'delegatedTasksToMe' ||
            elm.source === 'sharedTasksToMe' ||
            elm.source === 'delegatedTasksToOthers' ||
            elm.source === 'sharedTasksToOthers')
        )
    )
  }

  return aggregatedData
}

const handleVirtualWorkItems = (aggregatedData) => {
  // Individual task that has list should be displayed as list (a list with a single task, with limited
  // feature e.g. cannot be redelegated/reshared)
  // - Applied for both shared and delegated tasks
  // How
  // - Convert individual tasks with list into new type of list called "Virtual list"
  // - Virtualist implementation required on the UI to display properly
  // - Scan through work items that are virtual list and try to see if the list ids are duplicated
  //   if so mean we can combine them.
  // - Combine them and recalulate sorting date, original items must be removed after combined.
  const individualTasksWithList = []
  aggregatedData
    .filter((elm) => elm.source === 'delegatedTasksToMe' || elm.source === 'sharedTasksToMe')
    .map((elm) => {
      if (elm.data.checklist !== null) individualTasksWithList.push(elm)
    })

  const distinctChecklists = []
  individualTasksWithList.map((elm) => {
    if (distinctChecklists.filter((elm2) => elm2.id === elm.data.checklist.id).length === 0) {
      distinctChecklists.push(elm.data.checklist)
    }
  })

  distinctChecklists.map((elm) => {
    const tasks = individualTasksWithList.filter((elm2) => elm2.data.checklist.id === elm.id)

    if (tasks.length > 0) {
      const source = (() => {
        if (tasks[0].source === 'delegatedTasksToMe') return 'delegatedChecklistsToMe'
        else if (tasks[0].source === 'sharedTasksToMe') return 'sharedChecklistsToMe'
        else return ''
      })()

      const virtualChecklist = {
        type: 'virtualList',
        source,
        sortingDate: getMinDateFromChecklistWorkItem(elm),
        data: {
          ...tasks[0].data.checklist,
          tasks: tasks.map((elm2) => {
            return { ...elm2.data }
          })
        }
      }

      aggregatedData = [...aggregatedData, virtualChecklist]
    }
  })

  individualTasksWithList.map((elm) => {
    aggregatedData = aggregatedData.filter((elm2) => elm2.data.id !== elm.data.id)
  })

  // Case2: New requirement in new calendar style UI
  // Redelegated tasks will be moved away from the original list it contained
  const individualDelegatedTasksWithList = []
  aggregatedData
    .filter((elm) => elm.source === 'delegatedTasksToOthers')
    .map((elm) => {
      if (elm.data.checklist !== null) individualDelegatedTasksWithList.push(elm)
    })

  const distinctDelegatedChecklists = []
  individualDelegatedTasksWithList.map((elm) => {
    if (distinctDelegatedChecklists.filter((elm2) => elm2.id === elm.data.checklist.id).length === 0) {
      distinctDelegatedChecklists.push(elm.data.checklist)
    }
  })

  distinctDelegatedChecklists.map((elm) => {
    const tasks = individualDelegatedTasksWithList.filter((elm2) => elm2.data.checklist.id === elm.id)

    if (tasks.length > 0) {
      const source = (() => {
        if (tasks[0].source === 'delegatedTasksToOthers') return 'delegatedChecklistsToOthers'
        else return ''
      })()

      const virtualChecklist = {
        type: 'virtualList',
        source,
        sortingDate: getMinDateFromChecklistWorkItem(elm),
        data: {
          ...tasks[0].data.checklist,
          tasks: tasks.map((elm2) => {
            return { ...elm2.data }
          })
        }
      }

      aggregatedData = [...aggregatedData, virtualChecklist]
    }
  })

  individualDelegatedTasksWithList.map((elm) => {
    aggregatedData = aggregatedData.filter((elm2) => elm2.data.id !== elm.data.id)
  })

  return aggregatedData
}

const completedFilter = (elm) => {
  if (
    (elm.state.value === 'declined' || elm.state.value === 'rejected' || elm.state.value === 'overdue') &&
    elm.isCompleted
  )
    return false
  else return true
}

const middleware = (store) => (next) => async (action) => {
  if (action.type === 'todo/initialTodoData') {
    let aggregatedData = []
    const premadeTasks = await salextApi.get('/premadeTasks')
    if (premadeTasks.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'premadeTasks'),
        ...premadeTasks.data.map((elm) => {
          return {
            type: 'task',
            source: 'premadeTasks',
            sortingDate: elm.dueDate,
            data: elm
          }
        })
      ]
    }

    const premadeChecklists = await salextApi.get('/premadeChecklists')
    if (premadeChecklists.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'premadeChecklists'),
        ...premadeChecklists.data.map((elm) => {
          return {
            type: 'list',
            source: 'premadeChecklists',
            sortingDate: getMinDateFromChecklistWorkItem(elm),
            data: elm
          }
        })
      ]
    }

    const tasks = await salextApi.get('/tasks')
    if (tasks.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'tasks'),
        ...tasks.data.map((elm) => {
          return {
            type: 'task',
            source: 'tasks',
            sortingDate: elm.dueDate,
            data: elm
          }
        })
      ]
    }

    const checklists = await salextApi.get('/checklists')
    if (checklists.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'checklists'),
        ...checklists.data.map((elm) => {
          return {
            type: 'list',
            source: 'checklists',
            sortingDate: getMinDateFromChecklistWorkItem(elm),
            data: elm
          }
        })
      ]
    }

    const delegatedTasksToOthers = await salextApi.get('/delegates/tasks?type=delegatedToOthers')
    if (delegatedTasksToOthers.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'delegatedTasksToOthers'),
        ...delegatedTasksToOthers.data
          .filter((elm) => completedFilter(elm))
          .map((elm) => {
            return {
              type: 'task',
              source: 'delegatedTasksToOthers',
              sortingDate: elm.dueDate,
              data: elm
            }
          })
      ]
    }

    const delegatedTasksToMe = await salextApi.get('/delegates/tasks?type=delegatedToMe')
    if (delegatedTasksToMe.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'delegatedTasksToMe'),
        ...delegatedTasksToMe.data
          .filter((elm) => completedFilter(elm))
          .map((elm) => {
            return {
              type: 'task',
              source: 'delegatedTasksToMe',
              sortingDate: elm.dueDate,
              data: elm
            }
          })
      ]
    }

    const delegatedChecklistsToOthers = await salextApi.get('/delegates/checklists?type=delegatedToOthers')
    if (delegatedChecklistsToOthers.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'delegatedChecklistsToOthers'),
        ...delegatedChecklistsToOthers.data.map((elm) => {
          const filteredData = {
            ...elm,
            tasks: elm.tasks.filter((elm) => completedFilter(elm))
          }

          return {
            type: 'list',
            source: 'delegatedChecklistsToOthers',
            sortingDate: getMinDateFromChecklistWorkItem(filteredData),
            data: filteredData
            // sortingDate: getMinDateFromChecklistWorkItem(elm),
            // data: elm
          }
        })
      ]
    }

    const delegatedChecklistsToMe = await salextApi.get('/delegates/checklists?type=delegatedToMe')
    if (delegatedChecklistsToMe.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'delegatedChecklistsToMe'),
        ...delegatedChecklistsToMe.data.map((elm) => {
          const filteredData = {
            ...elm,
            tasks: elm.tasks.filter((elm) => completedFilter(elm))
          }
          return {
            type: 'list',
            source: 'delegatedChecklistsToMe',
            sortingDate: getMinDateFromChecklistWorkItem(filteredData),
            data: filteredData
          }
        })
      ]
    }

    const sharedTasksToOthers = await salextApi.get('/shares/tasks?type=sharedToOthers')
    if (sharedTasksToOthers.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'sharedTasksToOthers'),
        ...sharedTasksToOthers.data.map((elm) => {
          return {
            type: 'task',
            source: 'sharedTasksToOthers',
            sortingDate: elm.dueDate,
            data: elm
          }
        })
      ]
    }

    const sharedTasksToMe = await salextApi.get('/shares/tasks?type=sharedToMe')
    if (sharedTasksToMe.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'sharedTasksToMe'),
        ...sharedTasksToMe.data.map((elm) => {
          return {
            type: 'task',
            source: 'sharedTasksToMe',
            sortingDate: elm.dueDate,
            data: elm
          }
        })
      ]
    }

    const sharedChecklistsToOthers = await salextApi.get('/shares/checklists?type=sharedToOthers')
    if (sharedChecklistsToOthers.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'sharedChecklistsToOthers'),
        ...sharedChecklistsToOthers.data.map((elm) => {
          return {
            type: 'list',
            source: 'sharedChecklistsToOthers',
            sortingDate: getMinDateFromChecklistWorkItem(elm),
            data: elm
          }
        })
      ]
    }

    const sharedChecklistsToMe = await salextApi.get('/shares/checklists?type=sharedToMe')
    if (sharedChecklistsToMe.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'sharedChecklistsToMe'),
        ...sharedChecklistsToMe.data.map((elm) => {
          return {
            type: 'list',
            source: 'sharedChecklistsToMe',
            sortingDate: getMinDateFromChecklistWorkItem(elm),
            data: elm
          }
        })
      ]
    }

    const availableTasks = await salextApi.get('/commons/tasks')
    if (availableTasks.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'availableTasks'),
        ...availableTasks.data.map((elm) => {
          return {
            type: 'task',
            source: 'availableTasks',
            sortingDate: elm.dueDate,
            data: elm
          }
        })
      ]
    }

    const availableChecklists = await salextApi.get('/commons/checklists')
    if (availableChecklists.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'availableChecklists'),
        ...availableChecklists.data.map((elm) => {
          return {
            type: 'list',
            source: 'availableChecklists',
            sortingDate: getMinDateFromChecklistWorkItem(elm),
            data: elm
          }
        })
      ]
    }

    const publishedChecklists = await salextApi.get('/publishes/checklists')
    if (publishedChecklists.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'publishedChecklists'),
        ...publishedChecklists.data.map((elm) => {
          return {
            type: 'list',
            source: 'publishedChecklists',
            sortingDate: getMinDateFromChecklistWorkItem(elm),
            data: elm
          }
        })
      ]
    }

    aggregatedData = handleRedelegateItems(aggregatedData)
    aggregatedData = handleMultipleAssignments(aggregatedData)
    aggregatedData = handleVirtualWorkItems(aggregatedData)

    store.dispatch(setAggregatedWorkItems(aggregatedData))

    setTimeout(() => {
      store.dispatch(refreshCurrentSelections())
    }, 50)
  }

  if (action.type === 'todo/refreshPremadesData') {
    let aggregatedData = []

    const premadeTasks = await salextApi.get('/premadeTasks')
    if (premadeTasks.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'premadeTasks'),
        ...premadeTasks.data.map((elm) => {
          return {
            type: 'task',
            source: 'premadeTasks',
            sortingDate: elm.dueDate,
            data: elm
          }
        })
      ]
    }

    const premadeChecklists = await salextApi.get('/premadeChecklists')
    if (premadeChecklists.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'premadeChecklists'),
        ...premadeChecklists.data.map((elm) => {
          return {
            type: 'list',
            source: 'premadeChecklists',
            sortingDate: getMinDateFromChecklistWorkItem(elm),
            data: elm
          }
        })
      ]
    }

    aggregatedData = handleRedelegateItems(aggregatedData)
    aggregatedData = handleMultipleAssignments(aggregatedData)
    aggregatedData = handleVirtualWorkItems(aggregatedData)

    store.dispatch(replacePremadesData(aggregatedData))

    setTimeout(() => {
      store.dispatch(refreshCurrentSelections())
    }, 50)
  }

  if (action.type === 'todo/refreshDelegatesData ') {
    let aggregatedData = []

    const delegatedTasksToOthers = await salextApi.get('/delegates/tasks?type=delegatedToOthers')
    if (delegatedTasksToOthers.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'delegatedTasksToOthers'),
        ...delegatedTasksToOthers.data
          .filter((elm) => completedFilter(elm))
          .map((elm) => {
            return {
              type: 'task',
              source: 'delegatedTasksToOthers',
              sortingDate: elm.dueDate,
              data: elm
            }
          })
      ]
    }

    const delegatedTasksToMe = await salextApi.get('/delegates/tasks?type=delegatedToMe')
    if (delegatedTasksToMe.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'delegatedTasksToMe'),
        ...delegatedTasksToMe.data
          .filter((elm) => completedFilter(elm))
          .map((elm) => {
            return {
              type: 'task',
              source: 'delegatedTasksToMe',
              sortingDate: elm.dueDate,
              data: elm
            }
          })
      ]
    }

    const delegatedChecklistsToOthers = await salextApi.get('/delegates/checklists?type=delegatedToOthers')
    if (delegatedChecklistsToOthers.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'delegatedChecklistsToOthers'),
        ...delegatedChecklistsToOthers.data.map((elm) => {
          const filteredData = {
            ...elm,
            tasks: elm.tasks.filter((elm) => completedFilter(elm))
          }

          return {
            type: 'list',
            source: 'delegatedChecklistsToOthers',
            sortingDate: getMinDateFromChecklistWorkItem(filteredData),
            data: filteredData
            // sortingDate: getMinDateFromChecklistWorkItem(elm),
            // data: elm
          }
        })
      ]
    }

    const delegatedChecklistsToMe = await salextApi.get('/delegates/checklists?type=delegatedToMe')
    if (delegatedChecklistsToMe.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'delegatedChecklistsToMe'),
        ...delegatedChecklistsToMe.data.map((elm) => {
          const filteredData = {
            ...elm,
            tasks: elm.tasks.filter((elm) => completedFilter(elm))
          }
          return {
            type: 'list',
            source: 'delegatedChecklistsToMe',
            sortingDate: getMinDateFromChecklistWorkItem(filteredData),
            data: filteredData
          }
        })
      ]
    }

    aggregatedData = handleRedelegateItems(aggregatedData)
    aggregatedData = handleMultipleAssignments(aggregatedData)
    aggregatedData = handleVirtualWorkItems(aggregatedData)

    store.dispatch(replaceDelegatesData(aggregatedData))

    setTimeout(() => {
      store.dispatch(refreshCurrentSelections())
    }, 50)
  }

  if (action.type === 'todo/refreshSharesData') {
    let aggregatedData = []

    const tasks = await salextApi.get('/tasks')
    if (tasks.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'tasks'),
        ...tasks.data.map((elm) => {
          return {
            type: 'task',
            source: 'tasks',
            sortingDate: elm.dueDate,
            data: elm
          }
        })
      ]
    }

    const checklists = await salextApi.get('/checklists')
    if (checklists.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'checklists'),
        ...checklists.data.map((elm) => {
          return {
            type: 'list',
            source: 'checklists',
            sortingDate: getMinDateFromChecklistWorkItem(elm),
            data: elm
          }
        })
      ]
    }

    const sharedTasksToOthers = await salextApi.get('/shares/tasks?type=sharedToOthers')
    if (sharedTasksToOthers.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'sharedTasksToOthers'),
        ...sharedTasksToOthers.data.map((elm) => {
          return {
            type: 'task',
            source: 'sharedTasksToOthers',
            sortingDate: elm.dueDate,
            data: elm
          }
        })
      ]
    }

    const sharedTasksToMe = await salextApi.get('/shares/tasks?type=sharedToMe')
    if (sharedTasksToMe.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'sharedTasksToMe'),
        ...sharedTasksToMe.data.map((elm) => {
          return {
            type: 'task',
            source: 'sharedTasksToMe',
            sortingDate: elm.dueDate,
            data: elm
          }
        })
      ]
    }

    const sharedChecklistsToOthers = await salextApi.get('/shares/checklists?type=sharedToOthers')
    if (sharedChecklistsToOthers.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'sharedChecklistsToOthers'),
        ...sharedChecklistsToOthers.data.map((elm) => {
          return {
            type: 'list',
            source: 'sharedChecklistsToOthers',
            sortingDate: getMinDateFromChecklistWorkItem(elm),
            data: elm
          }
        })
      ]
    }

    const sharedChecklistsToMe = await salextApi.get('/shares/checklists?type=sharedToMe')
    if (sharedChecklistsToMe.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'sharedChecklistsToMe'),
        ...sharedChecklistsToMe.data.map((elm) => {
          return {
            type: 'list',
            source: 'sharedChecklistsToMe',
            sortingDate: getMinDateFromChecklistWorkItem(elm),
            data: elm
          }
        })
      ]
    }

    aggregatedData = handleRedelegateItems(aggregatedData)
    aggregatedData = handleMultipleAssignments(aggregatedData)
    aggregatedData = handleVirtualWorkItems(aggregatedData)

    store.dispatch(replaceSharesData(aggregatedData))

    setTimeout(() => {
      store.dispatch(refreshCurrentSelections())
    }, 50)
  }

  if (action.type === 'todo/refreshAvailableData') {
    let aggregatedData = []

    const availableTasks = await salextApi.get('/commons/tasks')
    if (availableTasks.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'availableTasks'),
        ...availableTasks.data.map((elm) => {
          return {
            type: 'task',
            source: 'availableTasks',
            sortingDate: elm.dueDate,
            data: elm
          }
        })
      ]
    }

    const availableChecklists = await salextApi.get('/commons/checklists')
    if (availableChecklists.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'availableChecklists'),
        ...availableChecklists.data.map((elm) => {
          return {
            type: 'list',
            source: 'availableChecklists',
            sortingDate: getMinDateFromChecklistWorkItem(elm),
            data: elm
          }
        })
      ]
    }

    aggregatedData = handleRedelegateItems(aggregatedData)
    aggregatedData = handleMultipleAssignments(aggregatedData)
    aggregatedData = handleVirtualWorkItems(aggregatedData)

    store.dispatch(replaceAvailableData(aggregatedData))

    setTimeout(() => {
      store.dispatch(refreshCurrentSelections())
    }, 50)
  }

  if (action.type === 'todo/refreshPublishedData') {
    let aggregatedData = []

    const publishedChecklists = await salextApi.get('/publishes/checklists')
    if (publishedChecklists.data.length > 0) {
      aggregatedData = [
        ...aggregatedData.filter((elm) => elm.source !== 'publishedChecklists'),
        ...publishedChecklists.data.map((elm) => {
          return {
            type: 'list',
            source: 'publishedChecklists',
            sortingDate: getMinDateFromChecklistWorkItem(elm),
            data: elm
          }
        })
      ]
    }

    aggregatedData = handleRedelegateItems(aggregatedData)
    aggregatedData = handleMultipleAssignments(aggregatedData)
    aggregatedData = handleVirtualWorkItems(aggregatedData)

    store.dispatch(replaceAvailableData(aggregatedData))

    // setTimeout(() => {
    //   store.dispatch(refreshCurrentSelections());
    // }, 50);
    setTimeout(async () => {
      await store.dispatch(setV2Data(constructV2Data()))
      refreshViewingData()
    }, 50)
  }

  if (action.type === 'todo/loadVariables') {
    const categories = await salextApi.get('/categories')
    if (categories.data.length > 0) {
      store.dispatch(setCategories(categories.data))
    }

    const importances = await salextApi.get('/importances')
    if (importances.data.length > 0) {
      store.dispatch(setImportances(importances.data))
    }

    const repetitions = await salextApi.get('/repetitions')
    if (repetitions.data.length > 0) {
      store.dispatch(setRepetitions(repetitions.data))
    }
  }

  if (action.type === 'todo/loadEntities') {
    const entities = await salextApi.get('/entities/trees')
    if (entities.data && entities.data.entity) {
      const userRootEntity = entities.data.entity
      const temp = []

      if (entities.data.entity.level <= 3) temp.push(userRootEntity)
      if (userRootEntity.level <= 2) {
        entities.data.subEntities.map((elm) => {
          temp.push(elm)
          if (userRootEntity.level === 1)
            elm.subEntities.map((elm2) => {
              temp.push(elm2)
            })
        })
      }

      store.dispatch(setEntities(temp))
    }
  }

  if (action.type === 'todo/loadUsers') {
    const users = await salextApi.get('/users')
    if (users.data.length > 0) {
      store.dispatch(setUsers(users.data))
    }
  }

  if (action.type === 'todo/loadGroups') {
    const groups = await salextApi.get('/groups')
    if (groups.data.length > 0) {
      store.dispatch(setGroups(groups.data))
    }
  }

  if (action.type === 'todo/completeTask') {
    const task = await salextApi.post(`/tasks/${action.payload?.id}/completions`)
    if (task.data) {
      // store.dispatch(initialTodoData())
    }
  }

  if (action.type === 'todo/uncompleteTask') {
    const task = await salextApi.delete(`/tasks/${action.payload?.id}/completions`)
    if (task.data) {
      // store.dispatch(initialTodoData())
    }
  }

  if (action.type === 'todo/declineTask') {
    const task = await salextApi.post(`/tasks/${action.payload?.id}/declines`, {
      reason: action.payload?.reason
    })
    if (task.data) {
      // store.dispatch(initialTodoData())
    }
  }

  if (action.type === 'todo/approveTask') {
    const task = await salextApi.post(`/tasks/${action.payload?.id}/approvals`, {
      feedbackComment: action.payload?.feedbackComment
    })
    if (task.data) {
      // store.dispatch(initialTodoData())
    }
  }

  if (action.type === 'todo/rejectTask') {
    const task = await salextApi.post(`/tasks/${action.payload?.id}/rejects`, {
      feedbackComment: action.payload?.feedbackComment
    })
    if (task.data) {
      // store.dispatch(initialTodoData())
    }
  }

  if (action.type === 'todo/suspendTask') {
    const task = await salextApi.post(`/tasks/${action.payload?.id}/suspensions`)
    if (task.data) {
      // store.dispatch(initialTodoData())
    }
  }

  if (action.type === 'todo/unsuspendTask') {
    const task = await salextApi.delete(`/tasks/${action.payload?.id}/suspensions`)
    if (task.data) {
      // store.dispatch(initialTodoData())
    }
  }

  if (action.type === 'todo/refreshCurrentSelections') {
    const currentTask = store.getState().todo.currentTask
    const currentChecklist = store.getState().todo.currentChecklist

    if (currentChecklist !== null) {
      store.dispatch(setCurrentChecklist(currentChecklist))
    }
    if (currentTask !== null) {
      store.dispatch(setCurrentTask(currentTask))
    }
  }

  if (action.type === 'todo/addChecklist') {
    const payload = action.payload
    const user = store.getState().setting.user
    let aggregatedData = store.getState().todo.aggregatedWorkItems
    aggregatedData = [
      ...aggregatedData,
      {
        type: 'list',
        source: payload.source,
        // sortingDate: payload.data.dueDate,
        data: {
          ...payload.data,
          tasks: [
            ...(() => {
              if (payload.data.delegates.filter((elm2) => elm2.user && elm2.user.id === user.id).length > 0) {
                return payload.data.tasks
              } else {
                return payload.data.tasks.filter(
                  (_task) => _task.delegates.filter((sub) => sub.user && sub.user.id === user.id).length > 0
                )
              }
            })()
          ]
        }
      }
    ]
    store.dispatch(setAggregatedWorkItems(aggregatedData))

    setTimeout(() => {
      store.dispatch(refreshCurrentSelections())
    }, 50)
  }

  if (action.type === 'todo/updateChecklist') {
    const payload = action.payload
    const user = store.getState().setting.user
    let aggregatedData = store.getState().todo.aggregatedWorkItems
  
    aggregatedData = aggregatedData.map((elm) => {
      if (elm.data.id !== payload.data.id) return elm
      else {
        console.log(elm.source, elm.data.id)
        if (elm.source === 'checklists') {
          return {
            ...elm,
            data: {
              ...payload.data
            }
          }
        }

        if (elm.source === 'publishedChecklists') {
          return {
            ...elm,
            data: {
              ...payload.data,
              tasks: [
                ...payload.data.tasks.filter(
                  (_task) => _task.delegates.filter((sub) => sub.delegatorUser.id === user.id).length === 0
                )
              ]
            }
          }
          //filter tasks: current user (SE level) must not delegate to someone else
        }

        if (elm.source === 'delegatedChecklistsToOthers') {
          return {
            ...elm,
            data: {
              ...payload.data,
              tasks: [
                ...(() => {
                  if (payload.data.delegates.filter((elm2) => elm2.delegatorUser.id === user.id).length > 0) {
                    return payload.data.tasks
                  } else {
                    return payload.data.tasks.filter(
                      (_task) => _task.delegates.filter((sub) => sub.delegatorUser.id === user.id).length > 0
                    )
                  }
                })()
              ]
            }
          }
          //filter tasks: keep ones that current user beling listed as delegator
        }

        if (elm.source === 'sharedChecklistsToOthers') {
          return {
            ...elm,
            data: {
              ...payload.data
            }
          }
        }

        if (elm.source === 'delegatedChecklistsToMe') {
          return {
            ...elm,
            data: {
              ...payload.data,
              tasks: [
                ...(() => {
                  if (payload.data.delegates.filter((elm2) => elm2.user && elm2.user.id === user.id).length > 0) {
                    return payload.data.tasks
                  } else {
                    return payload.data.tasks.filter(
                      (_task) => _task.delegates.filter((sub) => sub.user && sub.user.id === user.id).length > 0
                    )
                  }
                })()
              ]
            }
          }
        }

        if (elm.source === 'availableChecklists') {
          return {
            ...elm,
            data: {
              ...payload.data
            }
          }
        }

        if (elm.source === 'premadeChecklists') {
          return {
            ...elm,
            data: {
              ...payload.data
            }
          }
        }
      }
    })

    store.dispatch(setAggregatedWorkItems(aggregatedData))

    setTimeout(() => {
      store.dispatch(refreshCurrentSelections())
    }, 50)
  }

  if (action.type === 'todo/addTask') {
    const payload = action.payload
    let aggregatedData = store.getState().todo.aggregatedWorkItems
    aggregatedData = [
      ...aggregatedData,
      {
        type: 'task',
        source: payload.source,
        sortingDate: payload.data.dueDate,
        data: payload.data
      }
    ]

    store.dispatch(setAggregatedWorkItems(aggregatedData))

    setTimeout(() => {
      store.dispatch(refreshCurrentSelections())
    }, 50)
  }

  if (action.type === 'todo/updateTask') {
    const payload = action.payload
    let aggregatedData = store.getState().todo.aggregatedWorkItems
    aggregatedData = aggregatedData.map((elm) => {
      if (elm.data.id === payload.data.id) return { ...elm, sortingDate: payload.data.dueDate, data: payload.data }
      else return elm
    })

    // let isExistTask = false
    // aggregatedData = await aggregatedData.map((item) => {
    //   if (item.data.id !== payload.data.id) {
    //     if (item.type === 'list' && item.data.tasks.filter((it) => it.id === payload.data.id).length > 0) {
    //       isExistTask = true
    //       return {
    //         ...item,
    //         data: {
    //           ...item.data,
    //           tasks: item.data.tasks.map((i) => {
    //             // ? how to determind it is a delegated task and move it to virtual list?
    //             // ! it is not possible to do it on client site because it required user context to identify how to origanize task
    //             if (i.id === payload.data.id) {
    //               return payload.data
    //             }
    //             return i
    //           })
    //         }
    //       }
    //     }
    //     return item
    //   }

    //   // Otherwise, this is the one we want - return an updated value
    //   return {
    //     ...item,
    //     data: payload.data,
    //     sortingDate: payload.data.dueDate
    //   }
    // })
    // if (!isExistTask) {
    //   // item not found - new item
    //   aggregatedData = [
    //     ...aggregatedData,
    //     {
    //       type: payload.type,
    //       source: payload.source,
    //       sortingDate: payload.data.dueDate,
    //       data: payload.data
    //     }
    //   ]
    // }

    console.log('aggregatedData', aggregatedData)
    store.dispatch(setAggregatedWorkItems(aggregatedData))

    setTimeout(() => {
      store.dispatch(refreshCurrentSelections())
    }, 50)
  }

  if (action.type === 'todo/removeChecklist') {
    const payload = action.payload
    let aggregatedData = store.getState().todo.aggregatedWorkItems
    aggregatedData = [...aggregatedData.filter((elm) => elm.data.id !== payload.data.id)]

    store.dispatch(setAggregatedWorkItems(aggregatedData))

    setTimeout(() => {
      store.dispatch(refreshCurrentSelections())
    }, 50)
  }

  if (action.type === 'todo/removeTask') {
    const payload = action.payload
    let aggregatedData = store.getState().todo.aggregatedWorkItems
    aggregatedData = [...aggregatedData.filter((elm) => elm.data.id !== payload.data.id)]
    // check all tasks under checklist and remove it either
    aggregatedData = await aggregatedData.map((item) => {
      if (item.type !== 'list') {
        return item
      }

      if (item.data.tasks.filter((it) => it.id === payload.uuid).length > 0) {
        return {
          ...item,
          data: { ...item.data, tasks: item.data.tasks.filter((task) => task.id !== payload.uuid) }
        }
      }

      return item
    })
    store.dispatch(setAggregatedWorkItems(aggregatedData))

    setTimeout(() => {
      store.dispatch(refreshCurrentSelections())
    }, 50)
  }

  next(action)
}

export default middleware

/* eslint-disable no-unused-vars */
import { store } from '../../redux/store'
import {
  deletedTask,
  initialTodoData,
  refreshAvailableData,
  refreshCurrentSelections,
  refreshDelegatesData,
  refreshPremadesData,
  updateChecklist,
  updateTask,
  deletedChecklist,
  addChecklist,
  addTask,
  deleteTask,
  removeTask,
  removeChecklist
} from '../../redux/actions/todo'

export const handler = (type, payload) => {
  console.log('received-push-data: ', payload)
  switch (payload.type) {
    case 'updateChecklist': {
      if (payload.action === 'add') store.dispatch(addChecklist(payload.data, payload.source))
      else if (payload.action === 'update') store.dispatch(updateChecklist(payload.data))
      else if (payload.action === 'remove') store.dispatch(removeChecklist(payload.data))
      break
    }
    case 'updateTask': {
      if (payload.action === 'add') store.dispatch(addTask(payload.data, payload.source))
      else if (payload.action === 'update') store.dispatch(updateTask(payload.data))
      else if (payload.action === 'remove') store.dispatch(removeTask(payload.data))
      break
    }
    case 'reloadData': {
      store.dispatch(initialTodoData())
      break
    }
    case 'refreshMyData':
      // store.dispatch(initialTodoData())
      break
    case 'refreshPremadesData':
      store.dispatch(refreshPremadesData())
      break
    case 'refreshDelegatesData':
      store.dispatch(refreshDelegatesData())
      break
    case 'refreshSharesData':
      // store.dispatch(initialTodoData())
      break
    case 'refreshAvailableData':
      store.dispatch(refreshAvailableData())
      break
    case 'refreshCurrentSelections':
      store.dispatch(refreshCurrentSelections())
      break
    default:
      console.log('payload type not found: ')
      break
  }
}

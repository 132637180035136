import axios from 'axios'

import { ProtectedSocketClient } from './ProtectedSocketClient'
import { handler } from './handler'
import { constants } from '../../constants'
import { store } from '../../redux/store'
import { setAccessToken } from '../../redux/actions/auth'
import { setSocketConnectionStatus } from '../../redux/actions/socket'

const handlers = [handler]

export const socket = new ProtectedSocketClient(
  constants.protectedSocketUri,
  (type, payload) => {
    handlers.map((handle) => handle(type, payload))
  },
  {
    accessTokenGetter: () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          const accessToken = store.getState().auth.accessToken
          if (accessToken) resolve(store.getState().auth.accessToken)
          else resolve(null)
        }, 100)
      })
    },
    refreshTokenGetter: () => {
      return new Promise((resolve) => {
        setTimeout(() => {
          const refreshToken = store.getState().auth.refreshToken
          if (refreshToken) resolve(store.getState().auth.refreshToken)
          else resolve(null)
        }, 100)
      })
    },
    accessTokenSetter: (accessToken) => {
      store.dispatch(setAccessToken(accessToken))
    },
    accessTokenEnsurer: async (refreshToken) => {
      return new Promise(async (resolve, reject) => {
        try {
          const tokenRefreshResponse = await axios.post(
            `${constants.serviceBaseUrl}/auth/refresh`,
            {
              refresh_token: refreshToken,
              redirect_uri: constants.auth0RedirectUri
            },
            { headers: { 'Content-Type': 'application/json' } }
          )
          store.dispatch(setAccessToken(tokenRefreshResponse.data.access_token))
          resolve()
        } catch {
          reject()
        }
        // axios.defaults.headers.common['Authorization'] = `Bearer ${refreshToken}`
        // axios
        //   .post('auth/refresh', null, { baseURL: constants.serviceBaseUrl })
        //   .then((response) => {
        //     store.dispatch({
        //       type: 'UPDATE_JWT_TOKEN',
        //       payload: { accessToken: response.data.accessToken }
        //     })
        //     resolve(true)
        //   })
        //   .catch(() => {
        //     resolve(false)
        //   })
      })
    }
  },
  {
    connectCallback: () => {
      console.log(`Socket -> connect`)
      store.dispatch(setSocketConnectionStatus(true))
    },
    disconnectCallback: (data) => {
      console.log(`Socket -> disconnected: ${data}`)
      store.dispatch(setSocketConnectionStatus(false))
    },
    unauthorizeCallback: (data) => {
      console.log(`Socket -> unauthorized: ${data}`)
    },
    accessTokenExpiredCallback: (data) => {
      console.log(`Socket -> accessTokenExpired: ${data}`)
    },
    newAccessTokenIssuedCallback: (data) => {
      console.log(`Socket -> newAccessTokenIssued: ${data}`)
    }
  }
)
